import React, { useState } from "react";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Styles from './login.module.css'
import Utils, { MiniLogo } from "../../../../../Utils/Config";
import { Form, Input, Button, Radio, Modal, Checkbox } from "antd";
import ActionType from "../../../../../Store/actionType";
import { Link } from "react-router-dom";
import { Base64 } from 'js-base64';
function LoginComPage(props) {
    const { onTabStatus } = props
    const [radioValue, setRadioValue] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [formValue, setFormValue] = useState(null)
    // 登录表单提交
    const onFinish = (values) => {
        const isRadio = localStorage.getItem('isRadio')
        if (radioValue || isRadio) {
            let datas = values || formValue
            if (datas) {
                Utils.post(
                    {
                        url: 'api_v1/username2jwt/',
                        data: datas,
                        actionType: ActionType().Login,
                        Success: (res) => {
                            console.log(res)
                            localStorage.setItem("miniUser", Base64.encode(res?.username));
                            localStorage.setItem("miniCode", Base64.encode(datas.password));
                        }
                    }
                )
            }
        } else {

            if (!isRadio) {
                setFormValue(values)
                setIsModal(true)
            }
        }
    };

    return (
        <div>
            <div className={Styles.LoginPage}>
                <div className={Styles.LoginCenter}>
                    <div className={Styles.LoginLogo}>
                        <img src={MiniLogo()} alt="Logo" />
                        <span >
                            登录
                        </span>

                    </div>
                    <div className={Styles.loginForm}>
                        <Form
                            // labelCol={{ span: 5 }}
                            // wrapperCol={{ span: 19 }}
                            name="normal_login"
                            className={Styles.loginForms}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        // size='large'
                        >
                            <Form.Item
                                name="username"
                                className={Styles.loginInput}
                                rules={[
                                    { required: true, message: "Please fill in your username, phone number, or email address" },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="User name, phone or email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: "Please input your Password!" },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item
                                name="remember"
                                valuePropName="checked"
                            // wrapperCol={{
                            //     offset: 8,
                            //     span: 16,
                            // }}
                            >
                                <Checkbox > <span style={{ color: '#027e83' }}>Remember me</span></Checkbox>
                            </Form.Item>
                            <div className={Styles.loginButton}>
                                <Button className={Styles.loginSumit} type="primary" htmlType="submit" loading={false}>
                                    Login  登录
                                </Button>
                            </div>
                        </Form>
                    </div>
                    <div className={Styles.fromFooter}>
                        <Button className={Styles.gotoRegister} type="link" onClick={() => onTabStatus('resetPassWord')}>
                            Forgot password ？ <br />
                            忘记密码 ？
                        </Button>

                        <Button className={Styles.gotoRegister} type="link" onClick={() => onTabStatus('register')}>
                            New user registration  <br />
                            新用户注册
                        </Button>

                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Radio checked={radioValue} style={{ fontSize: '12px', color: '#999' }} onClick={() => {
                            setRadioValue(!radioValue)
                            localStorage.setItem('isRadio', '不是第一次了')
                        }}>
                            登陆代表您以阅读并同意
                            <Link>《数字信息》</Link> ,
                            <Link> 《保密协议》</Link>
                        </Radio>

                    </div>
                </div>
                <Modal
                    title={null}
                    footer={null}
                    open={isModal}
                    closeIcon={null}
                    onCancel={() => {
                        setIsModal(false)
                    }}
                // centered={true}
                >
                    <div>
                        请您认真阅读 <Link>《数字信息》</Link> 和 <Link> 《保密协议》</Link> 的全部条款，接受后可开始使用我的服务
                    </div>
                    <div className={Styles.loginModalFooter}>
                        <Button type='default' onClick={() => setIsModal(false)}>不同意</Button>
                        <Button type='primary' onClick={() => {
                            setRadioValue(true)
                            localStorage.setItem('isRadio', '不是第一次了')
                            setIsModal(false)
                            onFinish()
                        }}>同意</Button>
                    </div>
                </Modal>
            </div>

        </div>

    )
}

export default LoginComPage
