import { Button, Modal } from "antd"
import { useEffect, useState } from "react"
import Utils from "../../../../Utils/Config"
import ActionType from "../../../../Store/actionType"
import CountdownTimer from "../../store/time"
import QRCode from 'qrcode.react';
import chatPaySvg from './../../../../Static/images/chatPay.svg'
import Vips from './../../../../Static/images/vips.png'
import sao from './../../../../Static/images/sao.svg'
import Styles from './index.module.css'
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
let interval
let userInfo = JSON.parse(localStorage.getItem('userInfo'))
const isVip = userInfo?.vip_info?.expiring_time
const isVipTime = userInfo?.vip_info?.is_expire
const OpenVip = (props) => {
  const { comType, text, setIsIndex } = props
  const navigate = useNavigate();
  const [isOpend, setIsOpend] = useState(false)
  const [payLink, setPayLink] = useState(null)
  const [isTime, setIsTime] = useState(false);  //半个小时的倒计时
  const [payState, setPayState] = useState(null)

  const [gotoModal, setGotoModal] = useState(false)
  const vipSettingData = useSelector(data => data?.getVipSetting)
  const openVipChange = () => {
    if (userInfo) {
      Utils.post({
        url: 'api_v1/pay_vip_qr_link/',
        data: {
          wechatpay_type: 3
        },
        actionType: ActionType().patchStorePay,
        Success: (res) => {
          setIsTime(true)
          setPayLink(res.pay_link)
          setIsOpend(true)
          interval = setInterval(() => {
            Utils.get({
              url: 'api_v1/pay_result/' + res.order_id + '/',
              actionType: ActionType().getPayResult,
              Success: (resPay) => {
                if (resPay?.state > 1) {
                  setPayState(resPay.state)
                }
              },
              Error: () => {
                setPayState(null)
              }
            })
          }, 1000)
        }
      })
    } else {
      setGotoModal(true)
    }
  }
  const onGetUserInfo = () => {
    Utils.get({
      url: 'api_v1/username2jwt/',
      actionType: ActionType().Login,
      Success: (res) => {
        window.location.reload(true);
      }
    })
  }
  const gotoHome = () => {
    if (setIsIndex) {
      setIsIndex('index')
    } else {
      navigate('/')
    }
  }
  return (
    <>
      <Button onClick={() => openVipChange()}>
        {
          text || '开通VIP'
        }

      </Button>
      <Modal
        open={isOpend}
        title={null}
        footer={null}
        onCancel={() => {
          setIsOpend(false)
          clearInterval(interval)
          if (payState === 2) onGetUserInfo()
        }}
        width={450}
      >
        <div className={Styles.OpenVipModall}>
          <div className={Styles.PayTitle}>
            <img src={chatPaySvg} alt="Pay" />
            <div>
              <div className={Styles.PayTitleTitle}>微信支付</div>
              <div className={Styles.PayTitleSub}> WeChat Pay</div>
            </div>
          </div>
          {
            vipSettingData?.data?.price_info && <div className={Styles.PaySubPaice}>
              ￥{vipSettingData?.data?.price_info / 100}
            </div>
          }

          <div className={Styles.PayCenater}>
            {
              payLink && <QRCode value={payLink} renderAs="canvas" size={200} />
            }
            { // 支付成功后蒙版
              payState === 2 && <div className={Styles.PayCenaterMen} >
                <img src={chatPaySvg} alt="Pay" />
                <span>
                  恭喜您，支付成功！
                </span>

              </div>
            }
          </div>
          {
            payState !== 2 && <div style={{ textAlign: 'center', fontSize: '12px', }} >
              请您在
              {isTime && <CountdownTimer intervalTo={interval} />}
              内完成支付，如果支付成功后，页面没提示，请手动刷新页面
            </div>
          }


          <div className={Styles.PayFooter}>
            <img src={sao} alt="QR Codes" />

            <div>
              <div className={Styles.PayFooterTitle}>使用微信扫描二维码进行支付</div>
              <div className={Styles.PayFooterSub}>Use WeChat to scan QR codes for payment</div>
            </div>
          </div>
        </div>

      </Modal>

      {/* 提示登录  */}
      <Modal
        title={null}
        open={gotoModal}
        footer={null}
        onCancel={() => {
          setGotoModal(false)

        }}
        width={450}
      >
        <h2 style={{ textAlign: 'center' }}>温馨提示！</h2>
        <p className={Styles.gotoModalP}>
          对不起！您还未<span>登陆</span>或<span>注册</span>,
          请先完成<span>登陆</span>或<span>注册</span>后再购买或试用！
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between', }}>
          <Button type="link" onClick={() => gotoHome()}>
            去登陆
          </Button>

          <Button type="link" onClick={() => gotoHome()}>
            去注册
          </Button>

        </div>
      </Modal>
    </>
  )
}
export default OpenVip