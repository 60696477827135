import React, { useEffect, useState } from 'react'
import Utils, { MiniLogo } from "../../Utils/Config";
import { Button, Form, Input, Space, message } from "antd";
import { Link, useParams } from 'react-router-dom';
import Styles from './index.module.css'
const MRegister = () => {
  const [second, setSecond] = useState(60)
  const [isGetCode, setIsGetCode] = useState(false)
  const [allData, setAllData] = useState(null)
  const [isCode, setIsCode] = useState(false)
  const [isRegister, setIsRegister] = useState(false)
  const Params = useParams()
  useEffect(() => {
    onLinkTo()

  }, [second])

  const onLinkTo = () => {
    const spikeToken = Utils.getToken();
    if (spikeToken) {
      document.cookie = "jwt_token='';Path=/";
      localStorage.setItem('autoToken', '')
      localStorage.clear();
      window.location.reload(true);

    }
  }

  const onGetEamilCode = () => {
    if (!allData) return message.warning('没有填写手机号或邮箱，无法发送验证码！')
    const phoneRegex = /^1[3456789]\d{9}$/; // 手机号正则
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 邮箱正

    let urls, datas;
    if (phoneRegex.test(allData.user)) {
      // 处理手机号的逻辑
      urls = "api_v1/phone_code/"
      datas = {
        phone_number: allData.user
      }
    } else if (emailRegex.test(allData.user)) {
      // 处理邮箱的逻辑
      urls = "api_v1/email_code/"
      datas = {
        email: allData.user
      }
    } else {
      return message.error("输入格式不正确");
      // 处理格式不正确的逻辑
    }
    Utils.post({
      url: urls,
      data: datas,
      actionType: 'GetPhoneCode',
      Success: (res) => {
        setIsGetCode(true)
        message.success('验证码已发送，收到检证马后,请输入验证码完成注册')
        setIsCode(true)
        let setInterVal = setInterval(() => {
          if (second >= 1) {
            setSecond(prevSecond => {
              if (prevSecond <= 0) {
                setSecond(60)
                setIsGetCode(false)
                clearInterval(setInterVal)
              }
              return prevSecond - 1

            })

          }
        }, 1000);
      }
    })
  }
  const onValuesChange = (changedValues, allValues) => {
    setAllData(allValues)
  }

  const onFinish = (values) => {
    const phoneRegex = /^1[3456789]\d{9}$/; // 手机号正则
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 邮箱正
    if (!values.password) return message.error('密码不能为空')
    if (!values.code) return message.error('验证码不能为空')

    values['dealer_code'] = 'Dora30'
    if (Params && Params.code && Params.code) {
      values['dealer_code'] = Params && Params.code && Params.code
    }
    if (phoneRegex.test(values.user)) {
      // 处理手机号的逻辑
      values['phone_number'] = values.user

    } else if (emailRegex.test(values.user)) {
      // 处理邮箱的逻辑
      values['email'] = values.user
    } else {
      return message.error("输入格式不正确");
      // 处理格式不正确的逻辑
    }
    Utils.post({
      url: 'api_v1/create_user/',
      data: values,
      actionType: 'RegisterApi',
      Success: () => {
        setIsRegister(true)
        // dispatch({ type: 'HomeKey', data: '/Mlogin' })
        // navigate('/')
      }
    })


  }

  return (
    <div className={Styles.MRegister}>
      <div className={Styles.body}>
        <div className={Styles.img}><img src={MiniLogo()} alt='Logo' /></div>
        <div>
          <h2 style={{ textAlign: 'center' }}>
            欢迎来到 Dora30
            <br />
            开启你的阅读+词汇之旅
          </h2>
          {/* <p style={{ marginLeft: '20px' }}>
            新用户注册
          </p> */}
          <p style={{ textAlign: 'center', color: '#999' }}>
            新用户注册
            <br />
            请用您的电话号码(中国)
            <br />
            或者Email电子邮件注册账号

          </p>

        </div>
        <div style={{ padding: '0 20px' }} className={Styles.loginForm}>
          {isRegister && <div className={Styles.isRegister}>

            <h2>
              恭喜注册成功
            </h2>
            <p>
              请登陆后开始体验Dora30阅读词汇之旅
            </p>
            <div className={Styles.loginButton} style={{ marginTop: '10vh' }}>
              <Button className={Styles.gotoRegister} type='primary'><Link to='/Mlogin'>去登陆</Link></Button>
            </div>
          </div>}
          {!isRegister && <Form
            name="normal_register"
            size='large'
            onFinish={onFinish}
            onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
          >
            <Form.Item
              name={['user']}
              label="Phone or Email"
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone number or email address!',
                  pattern: /^1[3456789]\d{9}$|^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
                },
              ]}
            >
              <Input placeholder="Mobile phone number or email" />

            </Form.Item>

            <Form.Item
              name={['password']}
              label="密码"
              rules={[
                {
                  required: true,
                  message: '请您填写8-24位数的密码，其中要包含字母和数字',
                  pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,24}$/
                },
              ]}
            >
              <Input.Password placeholder="8-24位密码，包含字母和数字" />
            </Form.Item>
            <Space>
              <Form.Item
                name={['code']}
                label="验证码"
                rules={[
                  {
                    required: true,
                    message: '请填写你获取到的手机或者邮箱验证码',
                  },
                ]}
                getValueFromEvent={(e) => e.target.value.slice(0, 4)}
              >
                <Input
                  placeholder="验证码"
                  style={{ width: '200px' }}
                />
              </Form.Item>
              <Button style={{ marginTop: '25px' }} disabled={isGetCode} onClick={onGetEamilCode}>{
                isGetCode ? second + "s" : "发送验证码"
              }</Button>
            </Space>

            {
              isCode && <div style={{ textAlign: 'center', fontSize: '12px', color: 'red', marginBottom: '10px' }}>
                如虽没有收到验证码码.可又去SPAM里寻找

              </div>
            }
            <div className={Styles.loginButton}>
              <Button className={Styles.gotoRegister} type="link"><Link to='/Mlogin'>去登陆</Link></Button>
              <Button className={Styles.loginSumit} type="primary" htmlType="submit" loading={false}>
                注册
              </Button>

            </div>
            <div style={{ textAlign: 'center', color: '#999', fontSize: '12px' }}>
              <p>
                Dora30可以在网页端和微信小程序端（近期上线）同时使用
              </p>
            </div>
          </Form>}

        </div>
      </div>
    </div>
  )
}

export default MRegister