import React from "react";
import Utils, { IsPhone } from "./Config";
import LoginPage from "../Page/login/login";
import MLogin from "../MPage/MLogin";
import PermissionPage from "../Page/Permission";
import StudentHome from "../Page/Student/home";

export default function IsLogin(Component, permissions, permissions1) {
    let userInfo = localStorage.getItem('userInfo')

    if (userInfo === '[object Object]') {
        localStorage.clear();
        window.location.reload(true);
    }
    let user = JSON.parse(localStorage.getItem('userInfo'))
    if (user) user = user.user_info.codes
    //已登陆
    if (Utils.getToken()) {
        if (permissions === 'all') return <Component />
        if (user && user.length !== 0 && permissions) {
            if ((user.indexOf(permissions) !== -1) || (user.indexOf(permissions1) !== -1)) {
                return <Component />
            } else {
                return <PermissionPage />
            }
        } else {
            return <Component />
        }

    } else {   // 未登录
        if (IsPhone()) {
            return <MLogin />
        } else {

            // return <LoginPage />
            return <StudentHome />
        }

    }
}