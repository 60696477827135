import { Route, Routes, Link } from 'react-router-dom';
import React, { Suspense, lazy, useCallback, useEffect } from 'react';
import Styles from './index.module.css'
import { Avatar, Button, Col, Layout, Menu, Row, } from 'antd';
// import Logo from './../Static/images/dora30-logo-min.png'
import { useSelector, useDispatch } from 'react-redux';

import IsLogin from '../Utils/isLogin';
import Utils, { ColorData } from '../Utils/Config';
// import LoginPage from '../Page/login/login';
import Loading from '../Utils/loading';
import NotPage from './../Page/404/404';
// import RegisterPage from './../Page/register'
// import { SearchOutlined } from '@ant-design/icons';
import ActionType from '../Store/actionType';
import { EditOutlined, LogoutOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
// 订单

const OrderList = lazy(() => import('./../ComPonent/OrderList'))
// 修改密码
const ResetPasswordPage = lazy(() => import('./../Page/ResetPassword'))

//  编辑员
const EditWordQuestion = lazy(() => import('./../Page/Edit/wordQuestion'))
const WordPage = lazy(() => import('../Page/Edit/Word'))
const EditPrompt = lazy(() => import('./../Page/Edit/EditPrompt'))
const WrongWords = lazy(() => import('./../Page/Edit/Wrongwords'))
// const HomePage = lazy(() => import('../Page/Home'))

// 学生
const StudentHome = lazy(() => import('./../Page/Student/home'))
const StudentIndex = lazy(() => import('./../Page/Student'))
const ClassicsReading = lazy(() => import('./../Page/Student/classicsReading'))
const StudentStore = lazy(() => import('./../Page/Student/store'))
const StudentStoreDetails = lazy(() => import('../Page/Student/store/details'))
const StudentFriendsCode = lazy(() => import('./../Page/Student/friendsCode'))
// const StudentBindApplet = lazy(() => import('./../Page/Student/bindApplet'))
const StudentParentBind = lazy(() => import('./../Page/Student/parentBind'))
// const StudentTutorial = lazy(() => import('./../Page/Student/tutorial'))
const MyWrongWord = lazy(() => import('./../Page/Student/myWrongWord'))
// const StudentRelation = lazy(() => import('./../Page/Student/relation'))

const SearchArticle = lazy(() => import('./../Page/Student/search'))
// const ReadingList = lazy(() => import('./../Page/Student/readingList'))
const StudentClass = lazy(() => import('./../Page/Student/myClass'))

// 个人中心
const UserInfo = lazy(() => import('./../Page/userInfo'))

// 经销商
const DealerUser = lazy(() => import('./../Page/Dealer/myUser'))
const DealerCode = lazy(() => import('./../Page/Dealer/myCode'))
const ActivityList = lazy(() => import('../Page/Dealer/activitys'))
const DealerTeacher = lazy(() => import('../Page/Dealer/teacher'))
// 分享专用页面
const SharePage = lazy(() => import('./../Page/Student/share'))

// 老师页面

const TeacherHome = lazy(() => import('./../Page/Teacher/home'))

// AiAudio

const AiAudioPage = lazy(()=>import('./../Page/AiAudio'))
const { Header, Content } = Layout;
function AppRoter() {

    // 全局色卡
    // const onGetTokenColor = () => {
    //     Utils.get({
    //         url: 'api_v1/color_student/',
    //         params: {
    //             group_model: 1,
    //             page: 1,
    //             page_size: 1000,
    //         },
    //         actionType: ActionType().getTokenHeaderColor,

    //     })
    // }
    // const colorData = useSelector(data => data.getTokenHeaderColor)

    const dispatch = useDispatch();


    const onInitHomekey = useCallback(() => {
        const pathname = window.location.pathname;
        dispatch({ type: 'HomeKey', data: pathname })
    }, [dispatch]);
    useEffect(() => {
        onInitHomekey()
        // onGetTokenColor()
    }, [onInitHomekey])

    let HomeItems = []
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const isUser = (data) => {
        return userInfo?.user_info?.codes?.indexOf(data) !== -1
    }
    const OnMenuClick = (key) => {
        if (key.key === 'logout') {
            document.cookie = "jwt_token='';Path=/";
            localStorage.setItem('autoToken', '')
            localStorage.clear();
            window.location.reload(true);
            if ((isUser('word_editor')) || (isUser('admin'))) window.location.href = `${window.location.origin}/back/`;
        } else {
            dispatch({ type: 'HomeKey', data: key.key })
        }

    }

    if (userInfo && !userInfo.user_info) {
        localStorage.setItem('userInfo', null)
        userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }

    // 未登录
    if (!userInfo) {
        HomeItems = [
            {
                label: (<Link to={'/'}>Home</Link>),
                key: '/',
            },
            {
                label: (<Link to={'/login'}>Login</Link>),
                key: '/login',
            },
            {
                label: (<Link to={'/register'}>Register</Link>),
                key: '/register',
            },
        ]
    } else if (userInfo.user_info.codes.indexOf('teacher') !== -1) { //登录 老师
        HomeItems = [
            {
                label: (<Link to={'/'}>Class management</Link>),
                key: '/',
            },
            {
                label: (<Link to={'/parentBind'}> Mobile</Link>),
                key: '/parentBind'
            },
        ]
    } else if (userInfo.user_info.codes.indexOf('word_editor') !== -1) { //登录 编辑员
        HomeItems = [
            {
                label: (<Link to={'/'}>Word</Link>),
                key: '/',
            },

            {
                label: (<Link to={'/editPrompt'}>Edit Prompt</Link>),
                key: '/editPrompt',
            },
            {
                label: (<Link to={'/wordQuestion'}>Search</Link>),
                key: '/wordQuestion'
            }, {
                label: (<Link to={'/wrongWords'}>WrongWords</Link>),
                key: '/wrongWords'
            },
        ]
    } else if (userInfo.user_info.codes.indexOf('user') !== -1) {   // 登录学生
        HomeItems = [
            // {
            //     label: (<Link to={'/'}> Home</Link>),
            //     key: '/'
            // },
            {
                label: (<Link to={'/'}>标化考试</Link>),
                key: '/'
            },
            {
                label: (<Link to={'/classicsReading'}> 读名著</Link>),
                key: '/classicsReading'
            },

            {
                label: (<Link to={'/myWrongWord'}> Wordlist</Link>),
                key: '/myWrongWord'
            },
            {
                label: (<Link to={'/search'}> Search</Link>),
                key: '/search'
            },
            // {
            //     label: (<Link to={'/tutorial'}> Tutorial</Link>),
            //     key: '/tutorial'
            // },
            {
                label: (<Link to={'/parentBind'}> Mobile</Link>),
                key: '/parentBind'
            },
            // {
            //     label: (<Link to={'/bindApplet'}> Mobile</Link>),
            //     key: '/bindApplet'
            // },
            {
                label: (<Link to={'/shareThis'}> Share</Link>),
                key: '/shareThis'
            },

            // {
            //     label: (<Link to={'/readingList'}> Suggested Reading</Link>),
            //     key: '/readingList'
            // },
            {
                label: (<Link to={'/myClass'}> Class</Link>),
                key: '/myClass'
            },
            {
                label: (<Link to={'/store'}> Store</Link>),
                key: '/store'
            },


        ]
    } else if (userInfo.user_info.codes.indexOf('dealer') !== -1) {   // 登录销售
        HomeItems = [
            {
                label: (<Link to={'/'}> My User</Link>),
                key: '/'
            },
            {
                label: (<Link to={'/dealerCode'}> My Code</Link>),
                key: '/dealerCode'
            }, {
                label: (<Link to={'/orderList'}> My Sales</Link>),
                key: '/orderList'
            }, {
                label: (<Link to={'/activity'}> Activity</Link>),
                key: '/activity'
            }, {
                label: (<Link to={'/dealerTeacher'}> Teacher management</Link>),
                key: '/dealerTeacher'
            },

        ]
    } else if (userInfo.user_info.codes.indexOf('accounting') !== -1) {   // 登录财务
        HomeItems = [
            {
                label: (<Link to={'/'}> My Sales</Link>),
                key: '/'
            },
        ]
    } else if (userInfo.user_info.codes.indexOf('dealer_captain') !== -1) {   // 登录经销商组长
        HomeItems = [
            {
                label: (<Link to={'/'}> My Sales</Link>),
                key: '/'
            },
        ]
    } else {                                                        /// 登录为其他用户
        HomeItems = [
            {
                label: (<Link to={'/'}> Home</Link>),
                key: '/'
            },


        ]
    }
    const MenuUserChildren = isUser('user') ? [
        {
            label: (<Button type="link"><Link to={'/orderList'} ><MenuUnfoldOutlined />&nbsp; 我的订单</Link></Button>),
            key: 'orderList'
        },
        {
            label: (<Button type="link"><Link to={'/userInfo'} ><UserOutlined />&nbsp; 个人中心</Link></Button>),
            key: 'userInfo'
        },
        {
            label: (<Button type="link"><Link to={'/resetPassword'} ><EditOutlined />&nbsp; 修改密码</Link></Button>),
            key: 'resetPassword'
        },
        {
            label: (<Button type="link"><LogoutOutlined />退出登录</Button>),
            key: 'logout'
        }
    ] : [
        {
            label: (<Button type="link"><Link to={'/userInfo'} ><UserOutlined />&nbsp; 个人中心</Link></Button>),
            key: 'userInfo'
        },
        {
            label: (<Button type="link"><Link to={'/resetPassword'} ><EditOutlined />&nbsp; 修改密码</Link></Button>),
            key: 'resetPassword'
        },
        {
            label: (<Button type="link"><LogoutOutlined />退出登录</Button>),
            key: 'logout'
        }
    ]
    const MenuUser = [
        {
            label: (<div style={{ color: '#000' }}> Click on any word.</div>),
            key: '/null',
            disabled: true,
        },
        {
            label: (<Avatar >{userInfo?.user_info?.nick_name || 'Nick Name'}</Avatar>),
            key: 'user',
            children: MenuUserChildren
        },

    ]
    const contIsLogin = Utils.getToken()
    const HomeKey = useSelector(state => state.HomeKey);
    // const colors = colorData?.data?.results;
    // const headerBackgroud = `rgb(${colors?.[0]?.r},${colors?.[0]?.g},${colors?.[0]?.b})`
    const headerBackgroud = `#027e83`

    return (


        <Layout >
            {
                contIsLogin && <Header className={Styles.Header} style={{ background: headerBackgroud && headerBackgroud, color: ColorData().fontColor }}>
                    <Row align={'middle'}>

                        <Col xs={20} sm={20} md={16} lg={18} xl={19}>
                            <Menu
                                style={{
                                    background: headerBackgroud && headerBackgroud,
                                    '--menu-item-selected-color': ColorData().fontColor,
                                    '--menu-item-hover-color': ColorData().hoverColor,
                                    '--ant-menu-item-selected': ColorData().clickColor
                                }}
                                mode='horizontal'
                                selectedKeys={[HomeKey.data]}
                                onClick={OnMenuClick} items={HomeItems}
                                className={Styles.HeaderMenu}
                            />
                        </Col>

                        <Col xs={4} sm={4} md={8} lg={6} xl={5}>
                            {
                                contIsLogin ? <Menu
                                    style={{
                                        background: headerBackgroud && headerBackgroud,
                                        '--menu-item-selected-color': ColorData().fontColor,
                                        '--menu-item-hover-color': ColorData().hoverColor,
                                        '--ant-menu-item-selected': ColorData().clickColor
                                    }}
                                    selectedKeys={[HomeKey.data]}
                                    // theme='dark'
                                    mode='horizontal'
                                    triggerSubMenuAction='hover'
                                    onClick={OnMenuClick}
                                    items={MenuUser}
                                /> : <Link to='/login'>登陆</Link>
                            }
                        </Col>
                    </Row>
                </Header>
            }

            <Content className={Styles.Content}>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        {

                            userInfo ?
                                (
                                    <>
                                        {
                                            isUser('word_editor') ?
                                                <>
                                                    {/* 编辑员 */}
                                                    <Route path='/' element={IsLogin(WordPage, 'word_editor')} />
                                                    <Route path='/editPrompt' element={IsLogin(EditPrompt, 'word_editor')} />
                                                    <Route path='/wordQuestion' element={IsLogin(EditWordQuestion, 'word_editor')} />
                                                    <Route path='/wrongWords' element={IsLogin(WrongWords, 'word_editor')} />

                                                </> : isUser('teacher') ? <>
                                                    {/* 老师 */}
                                                    <Route path='/' element={IsLogin(TeacherHome, 'teacher')} />
                                                    <Route path='/parentBind' element={IsLogin(StudentParentBind, 'teacher')} />
                                                </> : isUser('dealer') ? <>
                                                    {/* 销售员 */}
                                                    <Route path='/' element={IsLogin(DealerUser, 'dealer')} />
                                                    <Route path='/dealerCode' element={IsLogin(DealerCode, 'dealer')} />
                                                    <Route path='/orderList' element={IsLogin(OrderList, 'dealer')} />
                                                    <Route path='/activity' element={IsLogin(ActivityList, 'dealer')} />
                                                    <Route path='/dealerTeacher' element={IsLogin(DealerTeacher, 'dealer')} />
                                                </> : isUser('accounting') ? <>
                                                    {/* 财务 */}
                                                    <Route path='/' element={IsLogin(OrderList, 'accounting')} />
                                                </> : isUser('dealer_captain') ? <>
                                                    {/* 经销商组长 */}
                                                    <Route path='/' element={IsLogin(OrderList, 'dealer_captain')} />
                                                </> : <>
                                                    {/* 学生  inspector 检查员   user 普通用户 */}
                                                    <Route path='/' element={IsLogin(StudentIndex, 'inspector', 'user')} />
                                                    <Route path='/classicsReading' element={IsLogin(ClassicsReading, 'inspector', 'user')} />
                                                    <Route path='/store' element={IsLogin(StudentStore, 'inspector', 'user')} />
                                                    <Route path='/search' element={IsLogin(SearchArticle, 'inspector', 'user')} />
                                                    {/* <Route path='/readingList' element={IsLogin(ReadingList, 'inspector', 'user')} /> */}
                                                    <Route path='/shareThis' element={IsLogin(StudentFriendsCode, 'inspector', 'user')} />
                                                    {/* <Route path='/bindApplet' element={IsLogin(StudentBindApplet, 'inspector', 'user')} /> */}
                                                    <Route path='/parentBind' element={IsLogin(StudentParentBind, 'inspector', 'user')} />
                                                    {/* <Route path='/tutorial' element={IsLogin(StudentTutorial, 'inspector', 'user')} /> */}
                                                    <Route path='/myWrongWord' element={IsLogin(MyWrongWord, 'inspector', 'user')} />
                                                    <Route path='/orderList' element={IsLogin(OrderList, 'inspector', 'user')} />
                                                    <Route path='/storeDetails/:id' element={IsLogin(StudentStoreDetails, 'inspector', 'user')} />
                                                    <Route path='/storeDetails/:id/:code' element={IsLogin(StudentStoreDetails, 'inspector', 'user')} />
                                                    <Route path='/myClass' element={IsLogin(StudentClass, 'user')} />
                                                    {/* <Route path='/myBook' element={IsLogin(StudentMyBook, 'inspector', 'user')} /> */}
                                                    {/* <Route path='/relation/:testGroupId' element={IsLogin(StudentRelation, 'inspector')} /> */}
                                                </>
                                        }
                                        <Route path='/userInfo' element={IsLogin(UserInfo, 'all')} />
                                        <Route path='/resetPassword' element={<ResetPasswordPage />} />
                                    </>
                                ) :
                                <>
                                    {/* 未登录 */}
                                    <Route path='/' element={<StudentHome />} />
                                </>
                        }
                        <Route path='/AiAudioPage' element={<AiAudioPage />} />
                        <Route path='/SharePage/:id/:code' element={<SharePage />} />
                        <Route path="*" element={<NotPage />} />

                    </Routes>
                </Suspense>
            </Content>
        </Layout >


    );
}

export default AppRoter