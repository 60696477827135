
import { Layout, message } from 'antd'
import Styles from './index.module.css'
import { MiniLogo, iphoneCenter } from '../../Utils/Config';
import { Suspense, lazy } from 'react';
import Loading from '../../Utils/loading';
import { Link, Route, Routes } from 'react-router-dom';
import MLogin from '../MLogin';
import NotPage from './../../Page/404/404';
import MRegister from './../MRegister'
// import MindexJpg from './../../Static/images/Mindex.jpg'
// import IsLogin from '../../Utils/isLogin';
const MHome = lazy(() => import('./../MHome'))
const MStorePayOk = lazy(() => import('./../MStorePayOk'))
const MStore = lazy(() => import('./../MStore'))
const { Header, Content } = Layout;
const MRouter = () => {

  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const onLogOut = () => {
    document.cookie = "jwt_token='';Path=/";
    localStorage.setItem('autoToken', '')
    localStorage.clear();
    window.location.reload(true);
    if ((userInfo.user_info.codes.indexOf('word_editor') !== -1) || (userInfo.user_info.codes.indexOf('admin') !== -1)) window.location.href = `${window.location.origin}/back/`;

  }
  // 判断是否为微信内支付还是微信外置浏览器支付
  const onIsWeChat = () => {
    return /MicroMessenger/i.test(navigator.userAgent);
  }
  return (
    <div className={Styles.MRouter}>
      {
        onIsWeChat() ? <div className={Styles.MindexJpg}>
          {/* <img src={MindexJpg} /> */}
        </div> : <Layout>
          <Header className={Styles.Header} style={{ padding: "0 20px" }}>
            <div className={Styles.headers}>
              <div >
                <Link className={Styles.logoDiv} to='/'><img className={Styles.logo} src={MiniLogo()} alt='logo' /></Link>
              </div>

              <div >
                {
                  userInfo ? <div className={Styles.loginRes} style={{ padding: '0 20px' }} onClick={() => onLogOut()}>
                    <div className={Styles.ResButton}> {userInfo?.user_info?.nick_name || 'Nick name'}</div>
                    <div className={Styles.loginButton} style={{ color: '#4395ff' }}>
                      退出登陆
                    </div>

                  </div> : <div className={Styles.loginRes}>
                    <div className={Styles.ResButton}><Link to={'/Mregister'}>注册</Link></div>
                    <div className={Styles.loginButton}>
                      <Link to={'/Mlogin'}>登录</Link>
                    </div>
                  </div>
                }

              </div>
            </div>

          </Header>
          <Content>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path='/' exact element={<MHome />} />
                <Route path='/Mlogin' element={<MLogin />} />
                <Route path="/Mregister" element={<MRegister />} />
                <Route path="/Mregister/:code" element={<MRegister />} />
                <Route path='/MStorePayOk' element={<MStorePayOk />} />
                <Route path='/MStore' element={<MStore />} />
                <Route path="*" element={<NotPage />} />
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      }

    </div>
  )
}

export default MRouter