import axios from "axios";
import { message, notification } from "antd";

// import { useDispatch } from 'react-redux';
import store from './../Store/index'
// import actionType from "../store/actionType";
import Logo from './../Static/images/dora30-logo.png'
import MiniLogos from './../Static/images/dora30-logo-min1.png'
import MiniLogosbai from './../Static/images/dora30-logo-min1.png'
import { CheckCircleOutlined } from '@ant-design/icons'


class Util {

    // 全局logo
    getLogo() {
        return Logo
    }

    // 获取token
    getToken() {
        return localStorage.getItem("spikeToken");
    }
    // 获取当前rul
    wordUrl() {
        let http = window.location.protocol;
        let host = window.location.host;
        let ip = `${http}//${host}/`;
        ip = ip.replace(":3000", ":8000");
        ip = ip.replace(":3001", ":8001");
        ip = ip.replace('172.17.0.2', '127.0.0.1');
        return ip;
    }
    // 初始化axios配置
    // Axios() {
    //     const instance = axios.create({
    //         baseURL: this.wordUrl()
    //     });
    //     return instance
    // }

    // 是否为本地
    isLocal() {
        let port = window.location.port;
        let hostHome = window.location.hostname;
        if (
            hostHome === "127.0.0.1" ||
            hostHome === "localhost" ||
            port === "3000" ||
            port === "3001"
        ) {
            return true;
        } else {
            return false;
        }
    }
    requestUrl(path) {
        let paths = this.wordUrl() + path
        return paths
    }

    get(data) {
        data["method"] = "GET";
        this.axiosRequest(data)
    }
    post(data) {
        data["method"] = "POST";
        this.axiosRequest(data)
    }
    delete(data) {
        data["method"] = "DELETE";

        return this.axiosRequest(data)
    }
    put(data) {
        data["method"] = "PUT";
        this.axiosRequest(data)
    }
    patch(data) {
        data["method"] = "PATCH";
        this.axiosRequest(data)
    }
    isUser = (userInfo, data) => {
        return userInfo?.user_info?.codes?.indexOf(data) !== -1
    }
    axiosRequest(data) {
        store.dispatch({ type: data['actionType'], loading: true })
        let headers = {
            Accept: "application/json",
            Authorization: this.getToken(),
        }
        data['headers'] = {
            ...headers,
            ...data.headers
        }
        let url = this.requestUrl(data['url'])
        data['url'] = url

        axios(data).then((res) => {
            if (res.status === 299) {
                // const [api, contextHolder] = notification.useNotification();
                message.warning('必须要购买才能查看')
                // window.location.href = '/store'
                // api.info({
                //     message: `试用结束！`,
                //     description:
                //         '对不起！产品试用已结束。请您前往Store进行购买，学习更多的单词。',

                //     placement: 'top',
                // });
            }
            let RequestData = res.data
            RequestData = this.returnIndexAB(RequestData)
            store.dispatch({ type: data['actionType'], data: RequestData, loading: false })
            if (data['actionType'] === 'Login') {
                if (this.isLocal()) {
                    let spikeToken = RequestData.jwt_token;
                    let userInfo = JSON.stringify(RequestData);
                    localStorage.setItem("spikeToken", spikeToken);
                    localStorage.setItem("userInfo", userInfo);
                    let path = window.location.pathname

                    if (path === '/login' || path === '/Mlogin') {
                        path = '/'
                    }
                    window.location.href = path;
                } else if (
                    this.isUser(RequestData, 'word_editor') ||
                    this.isUser(RequestData, 'admin') ||
                    this.isUser(RequestData, 'accounting') ||
                    this.isUser(RequestData, 'dealer_captain')
                ) {
                    console.log(this.isUser('accounting'))
                    message.error('此用户为后台管理人员，请前往后台登录')
                } else {
                    let spikeToken = RequestData.jwt_token;
                    let userInfo = JSON.stringify(RequestData);
                    localStorage.setItem("spikeToken", spikeToken);
                    localStorage.setItem("userInfo", userInfo);
                    let path = window.location.pathname

                    if (path === '/login' || path === '/Mlogin') {
                        path = '/'
                    }
                    window.location.href = path;
                }


            }
            if (data.Success) data.Success(RequestData)
            this.requestMessage(res, data.message)
        }).catch((error) => {

            store.dispatch({ type: data['actionType'], data: error, loading: false })
            if (data.Error) data.Error(error)
            this.requestMessage(error)
        })
    }
    requestMessage(data, Message) {
        let status = data.status
        // console.log(data)
        if (status === 200 || status === 201 || status === 204) {
            if (Message) message.success(<CheckCircleOutlined />)
        } else if (status === 401) {
            message.error(data.data.info)
        } else if (!status && data.code && data.code === 'ERR_BAD_REQUEST') {
            let errorText = (
                (
                    data.response && data.response.data
                ) ? (
                    (
                        data.response.data.username && data.response.data.username[0]
                    ) ||

                    data.response.data.error ||
                    data.response.data.info ||
                    data.response.data.index ||
                    data.response.data.non_field_errors ||
                    (data.response.data.chapter_name && data.response.data.chapter_name[0]) ||
                    (data.response.data.prompt_obj && data.response.data.prompt_obj[0]) ||
                    (data.response.data.article_name && data.response.data.article_name[0]) ||
                    data.response.data.detail ||
                    data.response.data
                ) :
                    '未知错误！请联系工作人员-ERR_BAD_REQUEST')
            if (data && data.response && data.response.data && data.response.data.detail && data.response.data.detail === 'Authentication credentials were not provided.') {
                localStorage.clear();
                window.location.reload(true);
            } else if (
                this.getToken() && data?.response?.status === 403
            ) {
                message.error('登录信息过期，请重新登录后重试！', 15)
            } else {
                message.error(
                    data.code + ':' + errorText
                )
            }

        } else {
            message.error(data.message || '未知错误！请联系工作人员-All')
        }


    }

    returnFileUrl(url) {
        if (!url) return url;
        let newUrl = url.substring(0, 5);
        if (newUrl === "https") {
            return url;
        } else if (newUrl === "data:") {
            return url;
        } else {
            return this.wordUrl() + url;
        }
    }

    returnIndexAB(data) {
        if (!data) return data
        if (!data.results) return data
        let datas = data.results
        datas.sort(function (a, b) {
            return a.index - b.index;
        })
        return { ...data, results: datas }
    }

    wordHighlight(article, word) {
        if (!article || !word) return article
        if (article) {
            article = article.replace(/\n/g, "\n")
            article = article.replace(/<br\s*\/?>/gi, '\n');
        };

        const woodsIndex = article.indexOf(word)
        const beforeWoods = article.slice(0, woodsIndex)
        const woods = <span style={{ color: 'red' }} onClick={() => console.log('woods clicked')}>
            {article.slice(woodsIndex, woodsIndex + word.length)}
        </span>
        const afterWoods = article.slice(woodsIndex + word.length)

        return (
            <span>
                {beforeWoods}
                {woods}
                {afterWoods}
            </span>
        )
    }
    copyToClipboard(text) {
        const input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        const success = document.execCommand('copy');
        document.body.removeChild(input);
        if (success) message.success(`成功复制--${text}`)
        if (!success) message.error(`复制失败，请手动输入--${text}`)
        return success;
    }
}

let Utils = new Util()
export default Utils

export function inputDebounce(fn, time) {
    let timer = null;
    return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(this, args);
        }, time);
    };
}

export function IsPhone() {
    if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
    ) {
        return true

    } else {
        return false
    }
}

export function utc_to_english(utc_str) {
    const monthMapping = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    };
    if (!utc_str) return null;
    let date_obj = new Date(utc_str);
    date_obj.setHours(date_obj.getHours() + 8);
    let year = date_obj.getFullYear();
    let month = date_obj.getMonth() + 1;
    const monthStr = monthMapping[month];
    let day = date_obj.getDate();

    let hours = date_obj.getHours();
    let minutes = date_obj.getMinutes();
    return `${hours}:${minutes} ${day}th ${monthStr},${year}`;
}
export function format(num) {
    var f = num < 10 ? "0" + num : num;
    return f;
}
export function times(utc_str) {
    if (!utc_str)
        return {
            time: null, /// 时间戳
            utcYear: null,
            utcMonth: null,
            utcDate: null,
            utcHours: null,
            utcMinUtes: null,
            utcHM: null,
            utcYMD: null,
            utcYMDHM: null,
        };
    let date_obj = new Date(utc_str);
    // let time = date_obj.getTime();
    let time = date_obj.getTime() + 8 * 60 * 60 * 1000;
    date_obj = new Date(time);

    let year = date_obj.getFullYear(); // 年
    let month = date_obj.getMonth() + 1; //月
    let day = date_obj.getDate(); //  日
    let hours = date_obj.getHours(); //  时
    let minutes = date_obj.getMinutes(); //  分
    let utcDataTime = {
        time: time, /// 时间戳
        utcYear: year,
        utcMonth: month,
        utcDate: day,
        utcHours: hours,
        utcMinUtes: minutes,
        utcHM: format(hours) + " : " + format(minutes),
        utcYMD: year + "-" + format(month) + "-" + format(day),
        utcYMDHM:
            year +
            "-" +
            format(month) +
            "-" +
            format(day) +
            " " +
            format(hours) +
            " : " +
            format(minutes),
    };
    return utcDataTime;
}

export function MiniLogo() {
    return MiniLogos
}
export function MiniLogobai() {
    return MiniLogosbai
}

export function iphoneCenter(iphone) {
    let iphones = "" + iphone;
    if (!iphone) return "******";
    return iphones.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
}
/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
// 将数组里的元素打乱
export function ArraryDeran(testArray) {
    if (!testArray) return testArray
    if (!Array.prototype.derangedArray) {
        Array.prototype.derangedArray = function () {
            for (var j, x, i = this.length; i; j = parseInt(Math.random() * i), x = this[--i], this[i] = this[j], this[j] = x);
            return this;
        };

    }
    let data = testArray.derangedArray()
    return data
}

export function ColorData() {
    return {
        fontColor: '#000',
        hoverColor: '#fff',
        clickColor: '#fff'
    }
}

export function isUserInspector() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo?.user_info?.codes?.indexOf('inspector') !== -1 || userInfo?.user_info?.codes?.indexOf('user') !== -1) {
        return true
    } else {
        return false
    }
}

export function string2json(string) {
    function get_json_index() {
        // 第1组
        let start_model_1 = string.indexOf('[');
        let end_model_1 = string.lastIndexOf(']') + 1;
        let wait_1 = start_model_1 !== -1 && end_model_1 !== -1;
        let diff_1 = end_model_1 - start_model_1;
        let group_1 = [start_model_1, end_model_1];

        // 第2组
        let start_model_2 = string.indexOf('{');
        let end_model_2 = string.lastIndexOf('}') + 1;
        let wait_2 = start_model_2 !== -1 && end_model_2 !== -1;
        let diff_2 = end_model_2 - start_model_2;
        let group_2 = [start_model_2, end_model_2];


        // 返回一组有效值
        if (wait_1 && !wait_2) return group_1;
        if (wait_2 && !wait_1) return group_2;
        if (!wait_2 && !wait_1) return null;

        // 返回一组范围更大的值
        return diff_1 > diff_2 ? group_1 : group_2;
    }

    let jsonIndex = get_json_index();
    if (!jsonIndex) return null;
    let jsonString = string.slice(jsonIndex[0], jsonIndex[1]);

    try {
        let json;
        json = JSON.parse(jsonString);
        return json;
    } catch (e) {
        if (e instanceof SyntaxError) {
            return null;
        } else {
            throw e;
        }
    }


}

export function ProcessingContent(processing) {
    if (!processing) return processing
    processing = processing.replace(/\n/g, '<br />');

    return processing
}