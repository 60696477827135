import React, { useState } from 'react'
import Utils, { MiniLogo } from "./../../../../../Utils/Config";
import { Button, Form, Input, message } from "antd";
import Style from './index.module.css'
import { useParams } from 'react-router-dom';
function ResetPasswordComPage(props) {
    const { onTabStatus } = props
    const [second, setSecond] = useState(60)
    const [isGetCode, setIsGetCode] = useState(false)
    const [allData, setAllData] = useState(null)
    const [isRegister, setIsRegister] = useState(false)
    const Params = useParams()

    const onGetEamilCode = () => {
        const phoneRegex = /^1[3456789]\d{9}$/; // 手机号正则
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 邮箱正

        let urls, datas;
        if (phoneRegex.test(allData.user)) {
            // 处理手机号的逻辑
            urls = "api_v1/phone_code/"
            datas = {
                phone_number: allData.user
            }
        } else if (emailRegex.test(allData.user)) {
            // 处理邮箱的逻辑
            urls = "api_v1/email_code/"
            datas = {
                email: allData.user
            }
        } else {
            return message.error("输入格式不正确");
            // 处理格式不正确的逻辑
        }
        Utils.post({
            url: urls,
            data: datas,
            actionType: 'GetPhoneCode',
            Success: (res) => {
                setIsGetCode(true)
                let setInterVal = setInterval(() => {
                    if (second >= 1) {
                        setSecond(prevSecond => {
                            if (prevSecond <= 0) {
                                setSecond(60)
                                setIsGetCode(false)
                                clearInterval(setInterVal)
                            }
                            return prevSecond - 1

                        })

                    }
                }, 1000);
            }
        })
    }
    const onValuesChange = (changedValues, allValues) => {
        setAllData(allValues)

    }

    const onFinish = (values) => {
        const phoneRegex = /^1[3456789]\d{9}$/; // 手机号正则
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/; // 邮箱正
        if (!values.password) return message.error('密码不能为空')
        if (!values.code) return message.error('验证码不能为空')

        if (Params && Params.code && Params.code) {
            values['dealer_code'] = Params && Params.code && Params.code
        }
        if (phoneRegex.test(values.user)) {
            // 处理手机号的逻辑
            values['phone_number'] = values.user

        } else if (emailRegex.test(values.user)) {
            // 处理邮箱的逻辑
            values['email'] = values.user
        } else {
            return message.error("输入格式不正确");
            // 处理格式不正确的逻辑
        }
        Utils.post({
            url: 'api_v1/reset_password/',
            data: values,
            actionType: 'RegisterApi',
            Success: () => {
                // onTabStatus('login')
                setIsRegister(true)
            }
        })
    }
    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The passwords entered twice are inconsistent'));
        },
    });
    return (
        <div>
            <div className={Style.RegisterPage} >
                <div className={Style.body}>
                    <div className={Style.img}>
                        <img src={MiniLogo()} alt='Logo' />
                        <span style={{ fontSize: '36px', fontWeight: '800' }} >
                            找回密码
                        </span>
                    </div>
                    <div style={{ padding: '20px' }}>
                        {isRegister && <div className={Style.isRegister}>

                            <h2>
                                恭喜重置密码成功
                            </h2>
                            <p>
                                请登陆后开始体验Dora30阅读词汇之旅
                            </p>
                        </div>}
                        {!isRegister && <Form
                            name="normal_register"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={onFinish}
                            onValuesChange={(changedValues, allValues) => onValuesChange(changedValues, allValues)}
                        >
                            <Form.Item
                                name={['user']}
                                label="Phone or Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your phone number or email address!',
                                        pattern: /^1[3456789]\d{9}$|^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
                                    },
                                ]}
                            >
                                <Input placeholder="Mobile phone number or email" />

                            </Form.Item>


                            <Form.Item
                                name={['password']}
                                label="New Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'At least contain letters, numbers, 8-24 digits',
                                        pattern: /^(?=.*[a-zA-Z])(?=.*\d).{8,24}$/
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    { required: true, message: 'Please confirm the password' },
                                    validateConfirmPassword,
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                            <Form.Item
                                name={['code']}
                                label="Code"
                                rules={[
                                    {
                                        required: true,
                                        type: 'code',
                                    },
                                ]}
                            >
                                <div className={Style.formCode}>
                                    <Input placeholder="Please enter the SMS verification code" />
                                    <Button disabled={isGetCode} onClick={onGetEamilCode}>{
                                        isGetCode ? second + "s" : "Get Code"
                                    }</Button>
                                </div>

                            </Form.Item>

                            <div className={Style.loginButton}>
                                <Button className={Style.loginSumit} type="primary" htmlType="submit" loading={false}>
                                    Submit
                                </Button>

                            </div>
                        </Form>}
                    </div>
                    <div className={Style.fromFooter}>
                        <Button className={Style.gotoRegister} type="link" onClick={() => onTabStatus('register')}>
                            New user registration
                            <br />
                            新用户注册
                        </Button>
                        <Button className={Style.gotoRegister} type="link" onClick={() => onTabStatus('login')}>
                            Go to login

                            <br />
                            登录
                        </Button>

                    </div>
                </div>
            </div>
            {/* <FooterCom /> */}
        </div>

    )
}

export default ResetPasswordComPage