import { Button, Carousel, Image, Modal, } from 'antd';
import Styles from './index.module.css'
// import FooterCom from '../../../ComPonent/footer';
import Utils, { MiniLogobai } from '../../../Utils/Config';
import LoginComPage from './Com/loginCom/login';
import { useState } from 'react';
import RegisterComPage from './Com/registerCom';
import ResetPasswordComPage from './Com/resetPassword';
import IndexImg from './../../../Static/images/indexImg.jpg'
import IndexImg1 from './../../../Static/images/indexImg1.jpg'
import Learning from './../../../Static/images/Learning.jpg'
import contactUs from './../../../Static/images/contactUs.jpg'
// import img0 from './../../../Static/images/2.png'
// import img1 from './../../../Static/images/3.png'
// import img2 from './../../../Static/images/4.png'
// import img3 from './../../../Static/images/5.png'
// import img4 from './../../../Static/images/6.png'
// import img5 from './../../../Static/images/7.png'
// import img6 from './../../../Static/images/8.png'
// import img7 from './../../../Static/images/9.png'
// import img8 from './../../../Static/images/10.png'
// import img9 from './../../../Static/images/11.png'
import StudentStore from '../store';
// import { RightCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import ActionType from '../../../Store/actionType';
import { useSelector } from 'react-redux';
const StudentHome = () => {
  const [statusLog, setStatusLog] = useState('login')
  const [indexModal, setIndexModal] = useState(false)
  const [modalType, setModalType] = useState('Introduce')
  const [isIndex, setIsIndex] = useState('index')

  const onGetIndexImage = () => {
    Utils.get({
      url: 'api_v1/useful_file/',
      params: {
        page: 1,
        page_size: 100,
        filter_tag: 2
      },
      actionType: ActionType().getIndexImage
    })
  }
  useEffect(() => {
    onGetIndexImage()
  }, [])
  const indexImageDataList = useSelector(data => data?.getIndexImage?.data?.results)
  const onTabStatus = (data) => {
    setStatusLog(data)
  }
  const FAQ = () => {
    return <div>
      {/* <h2>
        FAQ 常见问题
      </h2> */}
      <h3>1. Dora30 中的单词定义是字典里的吗？</h3>
      <p>
        不是。单词定义是根据字典定义用简单易懂的语言描述的，同时包含单词在不同语境下的用法和解释，使学生更好的领会单词意思和用法。Dora30 是学习单词的应用，和字典有本质区别。
      </p>
      <h3>2. Dora30 的目标和作用是什么？</h3>
      <p>
        目标是针对考试，高效拓展词汇量。作用是达到阅读和词汇同时进步。学习单词和背单词有本质区别。单词表是不可能长期记忆的，超过一周 90%都会忘记。在阅读过程中记住的单词，加上针对性的词汇练习，可以长期记忆。
      </p>
      <h3>3. 针对一个考试一般需要多长的时间准备？</h3>
      <p>
        每个单元一般有 20 个单词，一个考试需要 4000-5000 单词，这样是 200-250 个单元。每天两个单元，三个月可以完成。拓展词汇量要循序渐进，不可能一两周突击完成。
      </p>
      <h3>4. 每个单元需要多少时间完成？</h3>
      <p>
        15 - 30分钟。
      </p>
      <h3>5. 美国高中毕业生的词汇量有多少？</h3>
      <p>
        2 万到 3 万。
      </p>
      <h3>6. 学习单词可以代替读书吗？</h3>
      <p>
        不可以。认识汉字不等于会读书，英语也一样。在阅读过程中增加的词汇量是不会忘记的。
      </p>
      <h3>7. 阅读弱是因为单词量不够吗？</h3>
      <p>
        不是，阅读弱是因为阅读量不够。
      </p>
      <h3>8. 这些单词是如何选取的？</h3>
      <p>
        各个系列的单词是从官方网站和各种具有针对性的常见单词书中选取。
      </p>
      <h3>9. 什么是Lexile兰斯分级阅读？</h3>
      <p>
        Lexile 等级是一种标准化的量表，用于评估个人的阅读能力和文本的复杂性。从初学者的200L以下到高级文本的1600L以上，这个系统有助于将学生与符合他们当前阅读水平的书籍和其他阅读材料相匹配。这确保了具有挑战性但可管理的阅读体验，促进成长和学习。Lexile 等级在教育环境中被广泛使用，以跟踪阅读进展、设定目标和个性化学习。它们帮助教育工作者和家长了解学生的阅读优势和需要改进的领域，指导有效的教学并支持学术成就。
      </p>
    </div>
  }
  const About = () => {
    return (
      <>
        <p>
          We are dedicated to improving reading skills and vocabulary and building a solid fundation for various standard tests for students and adults . Our innovative Dora30 system is designed to boost effectiveness and efficiency in the learning process. Join us in our mission to empower individuals with enhanced reading capabilities and a broader vocabulary.
        </p>
        <p>
          We welcome individuals and organizations to collaborate and create a meaningful impact together. Let's enhance literacy and learning outcomes for all.
        </p>
        <p>
          我们致力于提高学生和成年人的阅读技巧和词汇量，为各种标准化考试打下坚实的基础。我们创新的Dora30系统旨在提高学习过程的效率和效果。加入我们的使命，让个体拥有更强的阅读能力和更广泛的词汇量。
        </p>
        <p>
          我们欢迎个人和组织合作，共同创造有意义的影响。让我们提升所有人的读写能力和学习成果。
        </p>
      </>
    )
  }
  const Contain = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <img src={contactUs} alt='Contact Us' style={{ width: '400px' }} />
        {/* <p>
          请发邮件：support@dora30.com
        </p> */}
      </div>
    )
  }
  const backgroundColors = '#027e83'
  const fontColors = '#fff'
  return (
    <div className={Styles.Studnet}>
      <div className={Styles.StudentHomePage} style={{ background: isIndex === 'store' && 'rgb(232,232,227)' }}>
        {/* header */}
        <div className={Styles.StudentHeader} style={{ background: isIndex === 'store' ? backgroundColors : backgroundColors, }} >
          <div className={Styles.HeaderLeft}>
            <div onClick={() => setIsIndex('index')} style={{ cursor: 'pointer' }}>
              <img className={Styles.HeaderLogo} src={MiniLogobai()} alt='Logo' />

              <div style={{ fontSize: '24px', fontWeight: '700', display: 'inline-block' }}>
                Dora30.com  探索单词，发现世界
              </div>
            </div>

            <div className={Styles.HeaderRight}>
              <Button onClick={() => {
                setIndexModal(true)
                setModalType('Introduce')
              }}>
                Introduction
                <br />
                介绍
              </Button>
              <Button onClick={() => {
                setIndexModal(true)
                setModalType('Learning')
              }}>
                Learning
                <br />
                学习路径
              </Button>
              <Button onClick={() => {
                setIndexModal(true)
                setModalType('FAQ')
              }}>
                FAQ
                <br />
                常见问题
              </Button>
              <Button onClick={() => setIsIndex('store')}>
                STORE
                <br />
                商店
              </Button>
              {
                isIndex === 'store' && <Button type='text' onClick={() => setIsIndex('index')}>
                  Home <br />
                  首页
                </Button>
              }
            </div>

          </div>

        </div>
        {/* body */}
        {
          isIndex === 'index' && <div className={Styles.StudentFooter1}>
            <div className={Styles.StudentFooters1}>
              {/* <div className={Styles.StudentFooters1List} onClick={() => setIsIndex('store')}>
                <div >
                  <p>TOFEL托福</p>
                  <p>英语四级</p>
                </div>
                <div>
                  <p>IETLS雅思</p>
                  <p>英语六级</p>
                </div>
                <div>
                  <p>SSAT/ISEE</p>
                  <p>中考英语</p>
                </div>
                <div>
                  <p>SAT/ACT</p>
                  <p>高考英语</p>
                </div>
                <div>
                  <p>GRE</p>
                  <p>专业四级</p>
                </div>
                <div>
                  <p>GMAT</p>
                  <p>专业八级</p>
                </div>
              </div> */}
              <div className={Styles.HeaderImages}>
                {indexImageDataList?.length !== 0 && indexImageDataList?.map((item, key) => <Image key={key} src={Utils.returnFileUrl(item.file_obj_read)} />)}
              </div>

            </div>

          </div>

        }

        {
          isIndex === 'index' && <>
            <div className={Styles.StudentHeaderSub}>
              <div className={Styles.HeaderSubBox}>
                <div className={Styles.HeaderSubBoxLeft}>
                  <div style={{ textAlign: 'center', padding: '40px 0 ' }}>
                    <h1>提升阅读能力 扩大词汇量</h1>
                    <h1>培养语感 为写作打基础</h1>
                    <Carousel autoplay  >
                      <div  >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={IndexImg} />
                        </div>
                      </div>
                      <div  >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <img src={IndexImg1} />
                        </div>
                      </div>

                    </Carousel>
                    {/* <p>
                      4000+单词 三个月
                    </p>
                    <p>
                      每日两练
                    </p>
                    <p>
                      每练15-20分钟
                    </p>
                    <p>
                      一元一个单词
                    </p> */}

                  </div>

                </div>
                {
                  statusLog === 'login' && <LoginComPage onTabStatus={(data) => onTabStatus(data)} />
                }
                {
                  statusLog === 'register' && <RegisterComPage onTabStatus={(data) => onTabStatus(data)} />
                }
                {
                  statusLog === 'resetPassWord' && <ResetPasswordComPage onTabStatus={(data) => onTabStatus(data)} />
                }

              </div>
              {/* <div className={Styles.IndexImg1} >
                <div style={{ backgroundColor: '#027e83', fontSize: '20px', fontWeight: '600', padding: '10px', color: '#fff' }}>
                  单词学习路径/考试的路径
                </div>
                <div className={Styles.IndexList}>
                  <div>
                    <div className={Styles.indexListS}>
                      <div>TOEFL/IETLS</div>
                      <div>托福/雅思</div>
                      <div>(语言类)</div>
                    </div>
                    <div className={Styles.indexListS}>
                      <div>SSAT/ISEE</div>
                      <div>申请美国中学</div>
                      <div>(综合类)</div>
                    </div>
                  </div>
                  <RightCircleOutlined style={{ color: '#027e83', fontSize: '40px' }} />
                  <div>
                    <div className={Styles.indexListS}>
                      <div>SAT/ACT</div>
                      <div>申请美国大学</div>
                      <div>(综合类)</div>
                    </div>
                  </div>
                  <RightCircleOutlined style={{ color: '#027e83', fontSize: '40px' }} />
                  <div>
                    <div className={Styles.indexListS}>
                      <div>GRE</div>
                      <div>申请美国研究生</div>
                      <div>(综合类)</div>
                    </div>
                    <div className={Styles.indexListS}>
                      <div>GMAT</div>
                      <div>申请美国商科研究生</div>
                      <div>(综合类)</div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className={Styles.StudentFooter}>
              <div className={Styles.StudentFooters}>
                <Button type='link' onClick={() => {
                  setIndexModal(true)
                  setModalType('About')
                }}> About Us 关于我们</Button>
                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer" style={{ color: 'rgb(187, 187, 187)' }}>沪ICP备2021017669号-2</a>
                <Button type='link' onClick={() => {
                  setIndexModal(true)
                  setModalType('Contact')
                }}> Contact Us 联系我们</Button>
              </div>
            </div>
            {/* <div className={Styles.StudentFooter}>
              <div className={Styles.StudentFooters}>
                <h1>
                  About Us关于我们
                </h1>
                <p>
                  We are dedicated to improving reading skills and vocabulary and building a solid fundation for various standard tests for students and adults . Our innovative Dora30 system is designed to boost effectiveness and efficiency in the learning process. Join us in our mission to empower individuals with enhanced reading capabilities and a broader vocabulary.
                </p>
                <p>
                  We welcome individuals and organizations to collaborate and create a meaningful impact together. Let's enhance literacy and learning outcomes for all.
                </p>
                <p>
                  我们致力于提高学生和成年人的阅读技巧和词汇量，为各种标准化考试打下坚实的基础。我们创新的Dora30系统旨在提高学习过程的效率和效果。加入我们的使命，让个体拥有更强的阅读能力和更广泛的词汇量。
                </p>
                <p>
                  我们欢迎个人和组织合作，共同创造有意义的影响。让我们提升所有人的读写能力和学习成果。
                </p>
              </div>
            </div>
            <div className={Styles.StudentFooter}>
              <div className={Styles.StudentFooters}>
                <h1>
                  Contact Us联系我们
                </h1>
                <p>
                  请发邮件：support@dora30.com
                </p>
              </div>
            </div> */}
          </>
        }

        {isIndex === 'store' && <StudentStore setIsIndex={(value) => setIsIndex(value)} />}

      </div>
      {/* <div style={{ background: isIndex === 'store' && 'rgb(184,255,224)' }} >
        <FooterCom />
      </div> */}



      <Modal
        title={
          modalType === 'Introduce' ? 'Introduce 介绍' :
            modalType === 'Learning' ? 'Learning 学习路径' :
              modalType === 'FAQ' ? 'FAQ 常见问题' :
                modalType === 'About' ? 'About Us关于我们' :
                  'Contact Us 联系我们'
        }
        open={indexModal}
        onCancel={() => setIndexModal(false)}
        footer={null}
        width={700}
      >
        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {modalType === 'Introduce' && <div className={Styles.Introduce}>
            <h2>
              1. Dora30和Spike20如何选择。
            </h2>
            <video controls controlsList="nodownload" muted src='https://static-1304762812.cos.ap-shanghai.myqcloud.com/Dora30App/introduce.mp4' />
            <h2>
              2. Dora30演示-对标标化考试。
            </h2>
            <video controls controlsList="nodownload" muted src='https://static-1304762812.cos.ap-shanghai.myqcloud.com/Dora30App/introduce2.mp4' />
            <h2>
              3. Dora30演示-读名著，学单词
            </h2>
            <video controls controlsList="nodownload" muted src='https://static-1304762812.cos.ap-shanghai.myqcloud.com/Dora30App/introduce3.mov' />
          </div>}
          {modalType === 'Learning' && <div style={{ textAlign: 'center' }}>
            {/* <img src={routerPng} alt='学习路径' style={{ width: '500px', borderRadius: '10px' }} /> */}
            <Carousel className={Styles.CarouselIndex} >
              <div  >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Image src={IndexImg} style={{ width: '650px', borderRadius: '10px' }} />
                </div>
              </div>
              <div  >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Image src={IndexImg1} style={{ width: '650px', borderRadius: '10px' }} />
                </div>
              </div>
              <div  >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Image src={Learning} style={{ width: '650px', borderRadius: '10px' }} />
                </div>
              </div>
            </Carousel>
          </div>}
          {modalType === 'FAQ' && <div>{FAQ()}</div>}
          {modalType === 'About' && <div>{About()}</div>}
          {modalType === 'Contact' && <div>{Contain()}</div>}
        </div>

      </Modal>
    </div >
  )
}

export default StudentHome