import React, { useEffect } from "react";
// import Logo from './../../Static/images/Spike20.word.com.png'
import style from './404.module.css'
import { Button } from "antd/es";
import { Link } from "react-router-dom";
import { MiniLogo } from "../../Utils/Config";
import { useNavigate } from 'react-router-dom';
const NotPage = () => {
    const navigate = useNavigate();
    useEffect(() => {

        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        // if (!userInfo) navigate('/login')
        if (!userInfo) navigate('/')
    })

    return (
        <div className={style.notPage}>
            <div>
                <div style={{ textAlign: 'center' }} ><img src={MiniLogo()} alt='logo' style={{ borderRadius: '10px', width: '200px' }} /></div>
                <div style={{ textAlign: 'center' }}>
                    <h2>404</h2>
                    <p>Sorry, the page you visited does not exist.</p>
                    <Button type='primary'><Link to={'/'}>Back Home</Link></Button>
                </div>

            </div>

        </div>
    )
}

export default NotPage